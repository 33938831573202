import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image';

import { StyledHeader, LogoContainer } from './styles';

const Header = ({ siteTitle, className, logoImage }) => {
  return (
    <StyledHeader className={className}>
      {logoImage && (
        <LogoContainer
          dimensions={
            logoImage ? logoImage.logo.dimensions : { width: 100, height: 75 }
          }
        >
          <Img fluid={logoImage.logoSharp.childImageSharp.fluid} />
        </LogoContainer>
      )}
    </StyledHeader>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
