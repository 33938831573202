import styled from 'styled-components';

export const FooterContainer = styled.footer`
  position: relative;
  bottom: var(--margin-tb);
  z-index: 1;
  left: Calc(50% + var(--margin-lr) * 3.5);
  right: Calc(var(--margin-lr) * 2.5);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  @media screen and (max-width: 1024px) {
    position: fixed;
    left: var(--margin-lr);
    bottom: var(--margin-tb);
    right: unset;
    z-index: -1;
    max-width: Calc(50vw - var(--margin-lr));
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    &.activeStep_0 {
      /* position: fixed; */
    }
  }
`;
export const LogoWrapper = styled.div`
  width: ${(props) => props.dimensions.width}px;
  height: ${(props) => props.dimensions.height}px;
  margin-right: 2rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    width: 135px;
    height: 30px;
  }
`;
export const MessageWrapper = styled.div`
  p {
    font-weight: 700;
    font-size: 0.6875rem;
    margin: 0;
    margin-top: 0.8125rem;
    line-height: 1em;
    font-style: normal;
    &:first-child {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    width: Calc(100vw - var(--margin-lr) * 2);
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    p {
      margin-top: 0;
      max-width: 37vw;
    }
  }
`;
