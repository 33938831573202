import styled from 'styled-components';

export const LogoContainer = styled.div`
  margin: 0 auto;
  right: var(--margin-lr);
  top: 4.875rem;
  position: absolute;
  display: inline-block;
  width: ${(props) => props.dimensions.width}px;
  height: ${(props) => props.dimensions.height}px;
  @media screen and (max-width: 1024px) {
    width: 173px;
    height: 84px;
    top: var(--margin-tb);
  }
  @media screen and (max-width: 768px) {
    width: 126px;
    height: 61px;
  }
`;

export const StyledHeader = styled.header`
  @media screen and (max-width: 768px) {
    ${LogoContainer} {
      top: Calc(50vh + var(--margin-tb));
    }

    &.activeStep_1 {
      ${LogoContainer} {
        top: Calc(32vh + var(--margin-tb));

        @media screen and (max-height: 768px) {
          top: Calc(245px + var(--margin-tb));
        }
      }
    }
    &.activeStep_0 {
      ${LogoContainer} {
        top: var(--margin-tb);
      }
    }
  }
`;
export const HeaderWrapper = styled.div``;
