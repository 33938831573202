import styled from 'styled-components';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import { createContext } from 'react';

export const MainWrapper = styled.div`
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: #efefed;
`;

export const Container = styled.div`
  margin: 0 auto;
  /* padding: 0px 1.0875rem; */
  padding-top: 0;
`;

export const CreateText = styled.p`
  a {
    color: black;
  }
`;

export const ShareWrapper = styled.div`
  width: 100%;
  max-width: 865px;
  margin: 2rem auto;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : 'initial')};
  flex-direction: row;
  flex-wrap: ${(props) => (props.wrap ? props.wrap : 'initial')};
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : 'initial'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'initial'};
  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  }
`;
export const Column = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 'initial')};
  flex-direction: column;
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : 'initial'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'initial'};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
`;
export const BackgroundReference = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 0;
  display: none;
  opacity: 50%;
  img {
    object-fit: cover;
  }
`;
export const StyledArticle = styled.article`
  h1 {
    font-size: 6.25rem;
    line-height: 0.96em;
    margin-top: 0.875rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 1024px) {
      font-size: 4.375rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 4rem;
    }
  }
  p {
    font-style: italic;
  }
`;

export const FormWrap = styled.form`
  padding: 4rem var(--margin-lr) 4rem 3.875rem;
  @media screen and (max-width: 1024px) {
    padding: var(--margin-tb) Calc(var(--margin-lr) * 2.5) var(--margin-tb)
      var(--margin-lr);
  }
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
`;
export const StyledTextField = styled(TextField)`
  width: 425px;
  &.rwmcTextArea {
    background-color: #fff;
    &:hover {
      background-color: #eee;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
