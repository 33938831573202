import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import scenery from '../images/endscreenConfirmation.png';

function SEO({ description, lang, meta, keywords, title, image }) {
  let metaImage = '';
  if (typeof window !== 'undefined' && window) {
    const qs = window.location.search;
    if (qs.indexOf('qs=') > -1) {
      const imgId = window.location.search.split('qs=')[1].split('&')[0];
      const imgLoc = `https://nod-2050-images.s3.ca-central-1.amazonaws.com/postcards/${
        window.location.search.split('qs=')[1].split('&')[0]
      }.jpg`;
      metaImage = imgLoc;
    }
  }
  if (metaImage === '' && image) {
    metaImage = image;
  } else if (metaImage === '' && !image) {
    metaImage = `https://nodfrom2050.ca${scenery}`;
  }
  return (
    <StaticQuery
      query={`${detailsQuery}`}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              // {
              //   name: `viewport`,
              //   content: `width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0`
              // },
              {
                name: 'viewport',
                content:
                  'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
              {
                name: 'twitter:image:src',
                content: metaImage,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : [],
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: null,
  image: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.string),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
