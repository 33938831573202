import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import { FooterContainer, LogoWrapper, MessageWrapper } from './styles';

const Footer = ({ className, data }) => {
  return (
    <FooterContainer className={className}>
      <LogoWrapper dimensions={data.footer_logo_image.dimensions}>
        {data.footer_logo_url && (
          <a href={data.footer_logo_url} target="_blank">
            <Img fluid={data.footer_logo_imageSharp.childImageSharp.fluid} />
          </a>
        )}
      </LogoWrapper>
      <MessageWrapper>
        <RichText render={data.footer_message} />
      </MessageWrapper>
    </FooterContainer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: ``,
};

export default Footer;
